import axios from 'axios'

export default function setup() {
axios.interceptors.request.use(function(config) {
    const auth_token = sessionStorage.getItem('token');
    const usuario = localStorage.getItem('usuario');
    if (config.method == 'post') {
          if(auth_token) {
            config.headers.Authorization = `Bearer ${auth_token}`;
            config.headers.usuario = usuario;
          }
    } else {
      config.headers.Authorization = `Bearer ${auth_token}`;
      config.headers.usuario = usuario;
    }
    return config;
  }, function(err) {
    localStorage.clear();
    sessionStorage.clear();
    return Promise.reject(err);
  });
}