<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">BANNER</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w =='1'" class="justify-end">
        <v-btn color="success" @click="agregarimagen=true">Agregar Banner</v-btn>
      </v-row>
      <v-card class="mt-10">
    <v-card-title>
      Total Categorias
      <v-spacer></v-spacer>
      <v-text-field
      color="green darken-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
      <v-data-table
            dense
            :headers="headers"
            :items="listadobanner"
            :search="search"
            multi-sort
            class="elevation-1"
          >
            <template v-slot:[`item.ruta`]="{ item }">
              <v-img
                style="cursor: pointer"
                max-height="73"
                max-width="240"
                :src="item.ruta"
                @click="verimagenampliada(item.ruta)"
              ></v-img>
            </template>
            <template v-slot:[`item.estado`]="{ item }">
              <v-chip
                class="
                  v-chip v-chip--label
                  theme--light
                  v-size--small
                  white--text
                "
                :color="`${getColor(item.estado)} darken-2`"
                label
                small
              >
                {{ item.estado }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <td>
                <v-icon v-if="item.estado =='A'" color="red" @click="CambiarEstado(item.id_banner,'I')"
                  >mdi-thumb-down</v-icon
                >
                <v-icon v-if="item.estado =='I'" color="success" @click="CambiarEstado(item.id_banner,'A')"
                  >mdi-thumb-up</v-icon
                >
              </td>
            </template>
          </v-data-table>
     <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    </v-card>
    </v-container>
        <!-- ventana de diálogo para eliminar registros -->
        <v-dialog v-model="verimagen" max-width="900" max-height="900">
          <v-card>
            <v-card-title class="headline">Imagen</v-card-title>
            <v-img max-height="900" max-width="900" :src="imagengrande"></v-img>
          </v-card>
        </v-dialog>
        <!-- ventana de diálogo cargar imagen slider -->
        <v-dialog v-model="agregarimagen" max-width="900" max-height="900">
          <v-card>
            <v-toolbar color="success" dark> Datos Slider</v-toolbar>
            <v-container fluid>
              <v-row>
                 <v-col class="d-flex" cols="12"  sm="6" md="6">
              <v-select
              color="green darken-2"
                v-model="datocliente"
                :items="listadoclientes"
                item-text="representante"
                item-value="id_cliente"
                label="Seleccionar Cliente"
                dense
                outlined
                required
              ></v-select>
            </v-col>
                <v-col class="d-flex" cols="6" sm="6">
                  <v-file-input
                  color="green darken-2"
                    v-model="banner_imagen_url"
                    accept="image/png, image/jpeg, image/bmp"
                    show-size
                    label="File input"
                    outlined
                    dense
                    id="archivosubir"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  color="green darken-2"
                    v-model="fechainicio"
                    label="FECHA INICIO"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechainicio"
                  @input="menu1 = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  color="green darken-2"
                    v-model="fechafin"
                    label="FECHA FIN"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechafin"
                  @input="menu2 = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>
            </v-col>
            </v-row>
              <v-row class="justify-center">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    elevation="8"
                    small
                    @click="agregarimagen = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="success"
                    elevation="8"
                    small
                    @click="GuardarBannerS()"
                  >
                    Guardar <v-icon right small>mdi-floppy</v-icon></v-btn
                  >
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
  </v-container>
    <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
export default {
  components: {},

  data() {
    return {
        search: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
          { text: 'CLIENTE', value: 'id_cliente' },
          { text: "IMAGEN", value: "ruta" },
          { text: 'FECHA INICIO', value: 'fecha_inicio' },
          { text: 'FECHA FIN', value: 'fecha_fin' },
          { text: 'ESTADO', value: 'estado' },
          { text: 'Actions', value: 'actions'},
        ],
        listadobanner:[],
        listadoclientes:[],
       banner_imagen_url:[],
       datocliente:"",
       menu1:false,
       menu2:false,
       fechainicio:"",
       fechafin:"",
      agregarimagen: false,
      verimagen: false,
      imagengrande: "",
      permisoacceso: [],
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[5];
    this.ListarBanner();
    this.ListarClientes();
  },
  methods: {
      ListarBanner: function () {
        axios.get("banner/listarbanner").then(
          function (response) {
            this.listadobanner = response.data;
          }.bind(this)
        );
      },
       ListarClientes: function () {
        axios.get("admin/listarclientes").then(
          function (response) {
            this.listadoclientes = response.data;
          }.bind(this)
        );
      },
    getColor (estado) {
        if (estado == "I") return 'red'
        else if (estado == "A") return 'green'
        else return 'orange'
      },
    verimagenampliada(url) {
      this.verimagen = true;
      this.imagengrande = url;
    },
         // ---------------------------------------------------------------------------------------------------------
    GuardarBannerS() {
      if (this.banner_imagen_url == "" || this.banner_imagen_url == null || this.banner_imagen_url == undefined ||
      this.datocliente == "" || this.datocliente == null || this.datocliente == undefined ||
      this.fechainicio == "" || this.fechainicio == null || this.fechainicio == undefined ||
      this.fechafin == "" || this.fechafin == null || this.fechafin == undefined
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        var datosenviar = {
          cliente:this.datocliente,
          fechainicio:this.fechainicio,
          fechafin:this.fechafin,
        }
        let formData = new FormData();
        formData.append("banner", this.banner_imagen_url);
        formData.append("datos", JSON.stringify(datosenviar));
        const datospermitidos = {"Content-Type": "multipart/form-data",};
        axios.post("/banner/guardarbanner",formData,datospermitidos)
          .then((respuesta) => {
          var msg = JSON.parse(respuesta.data[0].salida);
            this.banner_imagen_url=[];
            this.datocliente="";
            this.ListarBanner();
            this.agregarimagen = false;
            if(msg.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:msg.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:msg.MENSAJE,
                     icon:"error"})
                 }
          });
      }
    },
    // ---------------------------------------------------------------------------------------------------------
    CambiarEstado(id_banner,estado) {
       this.$swal.fire({
  title: 'Actualizar Estado',
  text: 'Desea Cambiar el Estado',
  icon: "question",
  showCancelButton: true,
  confirmButtonText: 'Si Cambiar',
}).then((result) => {
  if (result.isConfirmed) {
               const datosenviar = {
                 id_banner: id_banner,
                 estado:estado
               };
               axios.post("banner/cambiarestado", datosenviar)
                 .then((respuesta) => {
                  this.ListarBanner();
                 if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
                 })
  }});
    },
  },
};
</script>

<style>
</style>