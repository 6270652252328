<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">PAGOS</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-card class="mt-10">
    <v-card-title>
      Total Pagos
      <v-spacer></v-spacer>
      <v-text-field
      color="green darken-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
      <v-data-table
            dense
            :headers="headers"
            :items="listadopagos"
            :search="search"
            multi-sort
            class="elevation-1"
          >
            <template v-slot:[`item.estado_cobro`]="{ item }">
              <v-chip label small v-if="item.estado_cobro == 'P'" color="error">Pendiente</v-chip>
           <v-chip label small v-else-if="item.estado_cobro == 'I'" color="success">Pago</v-chip>
           <v-chip label small dark v-else color="indigo">Asignar Cobro</v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <td>
                   <v-btn
                  x-small
                  dark
                  color="success"
                  @click="VerCredito(item.id_negocio,item.nombre_negocio,item.id_cobro_u)"
                ><v-icon size="15"> mdi-cash-multiple </v-icon></v-btn>
              </td>
            </template>
          </v-data-table>
     <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    </v-card>
    </v-container>
        <!-- ventana de diálogo para eliminar registros -->
        <v-dialog v-model="verpagos" max-width="900" max-height="900">
          <v-card>
            <v-card-title class="headline">{{nombrenegocio}}</v-card-title>
            <v-container fluid>
            <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>NUMERO CUOTA</th>
              <th>CANTIDAD</th>
              <th>FECHA PAGO</th>
              <th>ESTADO</th>
              <th v-if="permisoacceso.x =='1'">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cob in listadocobrar" :key="cob.id_cuota">
              <td>{{ cob.numero_cuota }}</td>
              <td>{{ cob.cantidad_pagar }}</td>
              <td>{{ cob.fecha_pago }}</td>
              <td>
                <div v-if="cob.estado == 'P'">
                  <v-chip class="ma-2" color="error" label small>
                    Pendiente
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip
                    class="ma-2"
                    color="success"
                    text-color="white"
                    label
                    small
                  >
                    Pago
                  </v-chip>
                </div>
              </td>
              <td v-if="permisoacceso.x =='1'">
                <v-btn v-if="cob.cantidad_pagar == '' || cob.cantidad_pagar == null"  fab
                  rounded
                  x-small
                  dark
                  color="error"
                  @click="RealizarPago(cob.id_negocio_cobrar,cob.id_cuota,cob.id_cobro_unico)"
                ><v-icon> mdi-cash-multiple </v-icon></v-btn>
                <v-btn v-else fab
                  rounded
                  x-small
                  dark
                  color="success"
                >Pago</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-container>
          </v-card>
        </v-dialog>
        <!-- ventana de diálogo cargar imagen slider -->
        <v-dialog v-model="pagar" max-width="900" max-height="900">
          <v-card>
            <v-toolbar color="success" dark> Realizar Pago</v-toolbar>
            <v-container fluid>
              <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  color="green darken-2"
                    v-model="fechainicio"
                    label="FECHA INICIO"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechainicio"
                  @input="menu1 = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              color="green darken-2"
                v-model="cantidadpagar"
                label="Cantidad"
                placeholder="Cantidad"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            </v-row>
              <v-row class="justify-center">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    elevation="8"
                    small
                    @click="pagar = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="success"
                    elevation="8"
                    small
                    @click="GuardarPago()"
                  >
                    Guardar <v-icon right small>mdi-floppy</v-icon></v-btn
                  >
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
  </v-container>
    <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
export default {
  components: {},

  data() {
    return {
        search: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
          { text: 'CLIENTE', value: 'representante' },
          { text: "NEGOCIO", value: "nombre_negocio" },
          { text: 'FECHA INICIO', value: 'fecha_inicio' },
          { text: 'FECHA FIN', value: 'fecha_fin' },
          { text: 'ESTADO', value: 'estado' },
          { text: 'ESTADO COBRO', value: 'estado_cobro' },
          { text: 'ACCIONES', value: 'actions'},
        ],
       listadopagos:[],
       listadocobrar:[],
       nombrenegocio:"",
       idnegocio:[],
       cantidadpagar:"",
       id_negocio:"",
       id_pago_negociopagar:"",
       id_pagos_unicos:"",
       pagar:false,
       menu1:false,
       fechainicio:"",
        verpagos: false,
      permisoacceso: [],
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[6];
    this.ListarPagos();
  },
  methods: {
      ListarPagos: function () {
        axios.get("pago/listarpago").then(
          function (response) {
            this.listadopagos = response.data;
          }.bind(this)
        );
      },
        VerCredito: function (negocio,nombre,idunicocobro) {
            this.idnegocio = {negocio:negocio,idunicocobro:idunicocobro};
          axios.get(`pago/listarcredito/${negocio}&${idunicocobro}`).then(
            function (response) {
                this.nombrenegocio = nombre;
              this.listadocobrar = response.data;
              this.verpagos = true;
            }.bind(this)
          );
      },
        RealizarPago: function (id_negocio,id_pago,id_pago_unicos) {
            console.log(id_negocio);
              this.id_negocio = id_negocio;
              this.id_pago_negociopagar = id_pago;
              this.id_pagos_unicos = id_pago_unicos;
              this.pagar = !this.pagar
      },
    getColor (estado) {
        if (estado == "I") return 'red'
        else if (estado == "A") return 'green'
        else return 'orange'
      },
    // ---------------------------------------------------------------------------------------------------------
    GuardarPago() {
       this.$swal.fire({
  title: 'Registrar Pago',
  text: 'Desea Registrar El Pago',
  showCancelButton: true,
  confirmButtonText: 'Si Pagar',
}).then((result) => {
  if (result.isConfirmed) {
               const datosenviar = {
                 id_negocio: this.id_negocio,
                 id_pago: this.id_pago_negociopagar,
                 id_pagos_unicos: this.id_pagos_unicos,
                 cantidad:this.cantidadpagar,
                 fecha:this.fechainicio
               };
               axios.post("pago/pagar", datosenviar)
                 .then((respuesta) => {
                    this.VerCredito(this.idnegocio.negocio,this.nombrenegocio,this.idnegocio.idunicocobro)
                  this.pagar = false;
                 if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
                 })
  }});
    },
  },
};
</script>

<style>
</style>