<template>
          <v-card
    class="mx-auto"
    width="300"
  >
    <v-list>
      <v-list-item v-if="inicio.r == '1'" :to="'/inicio'">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title>INICIO</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="usuarios.r == '1'" :to="'/usuarios'">
        <v-list-item-icon>
          <v-icon>mdi-account-edit</v-icon>
        </v-list-item-icon>
        <v-list-item-title>USUARIOS</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="anuncios.r == '1'" :to="'/anuncios'">
        <v-list-item-icon>
          <v-icon>mdi-newspaper</v-icon>
        </v-list-item-icon>
        <v-list-item-title>ANUNCIOS</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="clientes.r == '1'" :to="'/clientes'">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>CLIENTES</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="categorias.r == '1'" :to="'/categorias'">
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title>CATEGORIAS</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="banner.r == '1'" :to="'/banner'">
        <v-list-item-icon>
          <v-icon>mdi-image-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>BANNER</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="pagos.r == '1'" :to="'/pagos'">
        <v-list-item-icon>
          <v-icon>mdi-cash-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>PAGOS</v-list-item-title>
      </v-list-item>
      <!-- ------------------------------------------------------ -->
     
    </v-list>
  </v-card>
</template>

<script>
export default {
data: () => ({
  inicio:[],
  usuarios:[],
  anuncios:[],
  clientes:[],
  categorias:[],
  banner:[],
  pagos:[],
    }),
    created() {
      var datospermisos = JSON.parse(localStorage.getItem("data"));
          this.inicio = datospermisos[0];
          this.usuarios = datospermisos[1];
          this.anuncios = datospermisos[2];
          this.clientes = datospermisos[3];
          this.categorias = datospermisos[4];
          this.banner = datospermisos[5];
          this.pagos = datospermisos[6];
  },
  methods:{
 
  }
}
</script>

<style scoped>
.v-list .v-list-item--active {
    color: green;
}
</style>