<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">CATEGORIAS</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w =='1'" class="justify-end">
        <v-btn color="success" @click="AgregarCategoria()">Agregar Categoria</v-btn>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Icono</th>
              <th>Estado</th>
              <th v-if="permisoacceso.x =='1'">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cate in listadocategorias" :key="cate.id_categoria">
              <td>{{ cate.nombre }}</td>
              <td>{{ cate.icono }}</td>
              <td>
                <div v-if="cate.estado == 'A'">
                  <v-chip class="ma-2" color="success" label small>
                    Activo
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip
                    class="ma-2"
                    color="red"
                    text-color="white"
                    label
                    small
                  >
                    Inactivo
                  </v-chip>
                </div>
              </td>
              <td v-if="permisoacceso.x =='1'">
                <v-btn  fab
                  rounded
                  x-small
                  dark
                  color="primary"
                  @click="Seleccionarcategoria(cate)"
                ><v-icon> mdi-pencil </v-icon></v-btn>
                <v-btn v-if="cate.estado =='A'"  fab
                  rounded
                  x-small
                  dark
                  color="red"
                  @click="CambiarEstado(cate.id_categoria,'I')"
                ><v-icon> mdi-thumb-down</v-icon></v-btn>
                <v-btn  v-if="cate.estado =='I'" fab
                  rounded
                  x-small
                  dark
                  color="success"
                  @click="CambiarEstado(cate.id_categoria,'A')"
                ><v-icon> mdi-thumb-up</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
        <!-- ventana de diálogo cargar imagen slider -->
        <v-dialog v-model="modalcategoria" max-width="900" max-height="900">
          <v-card>
            <v-toolbar color="primary" dark>{{titulo}}</v-toolbar>
            <v-container fluid>
              <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              color="green darken-2"
                v-model="datoscategoria.icono"
                label="Icono"
                placeholder="Icono"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              color="green darken-2"
                v-model="datoscategoria.nombre"
                label="Nombre"
                placeholder="Nombre"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
              <v-row class="justify-center">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    elevation="8"
                    small
                    @click="modalcategoria = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    :color="colorboton"
                    elevation="8"
                    small
                    v-on:click="
                  boton == 'Registrar' ? GuardarCategorias() : ActualizarCategoria()"
                  >
                    Guardar <v-icon right small>{{claseicono}}</v-icon></v-btn
                  >
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
  </v-container>
    <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
export default {
  components: {},

  data() {
    return {
      titulo: "",
      boton: "",
      colorboton: "",
      claseicono: "",
        listadocategorias:[],
      modalcategoria: false,
      datoscategoria:{
        nombre:"",
        icono:"",
        id_categoria:"",
      },
      permisoacceso: [],
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[4];
    this.ListarCategori();
  },
  methods: {
       ListarCategori: function () {
          axios.get('categorias/listarcategorias').then(
            function (response) {
              var msg = response.data;
              if(msg.CODIGO == 0){
                this.listadocategorias = msg.DATA;
                   this.$swal.fire({
                     title:"Exito",
                     text:msg.MENSAJE,
                     icon:"success"})
                 } else {
                  this.listadocategorias = [];
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:msg.MENSAJE,
                     icon:"error"})
                 }
            }.bind(this)
          );
      },
         // ---------------------------------------------------------------------------------------------------------
         AgregarCategoria() {
      this.modalcategoria = true;
      this.titulo = "Agregar Categoria";
      this.boton = "Registrar";
      this.colorboton = "primary";
      this.claseicono = "mdi-floppy";
      this.datoscategoria.icono = "";
      this.datoscategoria.nombre = "";
    },
    GuardarCategorias() {
      if (this.datoscategoria.icono == "" || this.datoscategoria.icono == null || this.datoscategoria.icono == undefined ||
      this.datoscategoria.nombre == "" || this.datoscategoria.nombre == null || this.datoscategoria.nombre == undefined 
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        axios.post("/categorias/guardarcategoria",this.datoscategoria)
          .then((respuesta) => {
            this.ListarCategori();
            this.modalcategoria = false;
            if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
          });
      }
    },
         // ---------------------------------------------------------------------------------------------------------
         Seleccionarcategoria(categoria) {
      this.titulo = "Editar Categoria";
      this.boton = "Actualizar";
      this.colorboton = "success";
      this.claseicono = "mdi-pencil";
      this.datoscategoria.icono = categoria.icono;
      this.datoscategoria.nombre = categoria.nombre;
      this.datoscategoria.id_categoria = categoria.id_categoria;
      this.modalcategoria = true;
    },
    ActualizarCategoria() {
      if (this.datoscategoria.icono == "" || this.datoscategoria.icono == null || this.datoscategoria.icono == undefined ||
      this.datoscategoria.nombre == "" || this.datoscategoria.nombre == null || this.datoscategoria.nombre == undefined 
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        axios.post("/categorias/actualizacategoria",this.datoscategoria)
          .then((respuesta) => {
            this.ListarCategori();
            this.modalcategoria = false;
            if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
          });
      }
    },
    // ---------------------------------------------------------------------------------------------------------
    CambiarEstado(id_categoria,estado) {
       this.$swal.fire({
  title: 'Actualizar Estado',
  text: 'Desea Cambiar el Estado',
  icon: "question",
  showCancelButton: true,
  confirmButtonText: 'Si Cambiar',
}).then((result) => {
  if (result.isConfirmed) {
               const datosenviar = {
                 id_categoria: id_categoria,
                 estado:estado
               };
               axios.post("categorias/cambiarestado", datosenviar)
                 .then((respuesta) => {
                  this.ListarCategori();
                 if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
                 })
  }});
    },
  },
};
</script>

<style>
</style>