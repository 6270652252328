<template>
<h1>no encontradas</h1>
</template>

<script>
export default {
    components:{
    },

data(){
  return{
        // totalpersona:"",

  }
},

 created() {
  }, 
  
  methods:{

  },
};
</script>

<style>
</style>