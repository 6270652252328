import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

import modulos from '../views/modulos'
import inicio from '../views/inicio'
import usuarios from '../views/usuarios'
import anuncios from '../views/anuncios'
import clientes from '../views/clientes'
import categorias from '../views/categorias'
import banner from '../views/banner'
import pagos from '../views/pagos'
import nofunciona from '../views/nofunciona'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/modulos',
    component: modulos,
    children: [
        {
            name: 'inicio',
            path: '/inicio',
            component: inicio,
            meta: { requiresAuth: true }
        },
        {
            name: 'usuarios',
            path: '/usuarios',
            component: usuarios,
            meta: { requiresAuth: true }
        },
        {
            name: 'anuncios',
            path: '/anuncios',
            component: anuncios,
            meta: { requiresAuth: true }
        },
        {
            name: 'clientes',
            path: '/clientes',
            component: clientes,
            meta: { requiresAuth: true }
        },
        {
            name: 'categorias',
            path: '/categorias',
            component: categorias,
            meta: { requiresAuth: true }
        },
        {
            name: 'banner',
            path: '/banner',
            component: banner,
            meta: { requiresAuth: true }
        },
        {
            name: 'pagos',
            path: '/pagos',
            component: pagos,
            meta: { requiresAuth: true }
        },
    ]
},
{
  path: '*',
  component: nofunciona
}
]

const router = new VueRouter({
  mode: '',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = sessionStorage.getItem('token');
    if (token) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});


export default router
