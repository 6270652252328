<template>
  <v-container v-if="permisoacceso.r == '1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">Anuncios</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w == '1'" class="justify-end">
        <v-btn color="primary" @click="AbrirModalAgregar()"
          >Agregar Anuncio</v-btn
        >
      </v-row>
      <v-card class="mt-10">
        <v-card-title>
          Total Anuncios
          <v-spacer></v-spacer>
          <v-text-field
            color="green darken-2"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          dense
          :headers="headers"
          :items="listadoanuncios"
          :search="search"
          multi-sort
          class="elevation-1"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.pagos`]="{ item }">
            <td v-if="permisoacceso.x == '1'">
              <v-btn
                v-if="item.estado_cobro == null || item.estado_cobro == ''"
                fab
                rounded
                x-small
                dark
                color="error"
                @click="Cargar_Cuotas_Pago(item.id_negocio)"
                ><v-icon>mdi-cash-multiple </v-icon></v-btn
              >
              <!-- <v-btn v-else fab rounded x-small dark color="info"
                ><v-icon>mdi-cash-multiple </v-icon></v-btn
              > -->
                <v-icon v-if="item.estado =='A'" color="red" @click="CambiarEstado(item.id_negocio,'I')"
                  >mdi-thumb-down</v-icon
                >
                <v-icon v-if="item.estado =='I'" color="success" @click="CambiarEstado(item.id_negocio,'A')"
                  >mdi-thumb-up</v-icon
                >
            </td>
            <td v-else>Sin Permiso</td>
          </template>
          <template v-slot:[`item.estadopagos`]="{ item }">
            <td v-if="permisoacceso.x == '1'">
              <v-chip label small v-if="item.estado_cobro == 'A'" color="error"
                >Debe</v-chip
              >
              <v-chip
                label
                small
                v-else-if="item.estado_cobro == 'I'"
                color="success"
                >Pago</v-chip
              >
              <v-chip label small v-else dark color="indigo"
                >Asignar Cobro</v-chip
              >
            </td>
            <td v-else>Sin Permiso</td>
          </template>
          <template v-slot:[`item.dias_faltantes`]="{ item }">
            <td>
              <v-chip
                label
                small
                v-if="item.dias_faltantes <= 5"
                color="error"
                >{{ item.dias_faltantes }}</v-chip
              >
              <v-chip
                label
                small
                v-else-if="item.dias_faltantes > 5 && item.dias_faltantes <= 10"
                color="primary"
                >{{ item.dias_faltantes }}</v-chip
              >
              <v-chip label small v-else color="success">{{
                item.dias_faltantes
              }}</v-chip>
            </td>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip
              class="
                v-chip v-chip--label
                theme--light
                v-size--small
                white--text
              "
              :color="`${getColor(item.estado)} lighten-2`"
              label
              small
            >
              {{ item.estado }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td v-if="permisoacceso.x == '1'">
              <v-btn
                fab
                rounded
                x-small
                dark
                color="primary"
                @click="SeleccionarAnuncio(item)"
                ><v-icon> mdi-pencil </v-icon></v-btn
              >
              <v-btn
                fab
                rounded
                x-small
                dark
                color="success"
                @click="CargarIMG(item.id_negocio)"
                ><v-icon> mdi-cloud-upload </v-icon></v-btn
              >
              <v-btn
                fab
                rounded
                x-small
                dark
                color="indigo"
                @click="VerImagenesNegocio(item.id_negocio)"
                ><v-icon> mdi-file-image </v-icon></v-btn
              >
            </td>
            <td v-else>Sin Permiso</td>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-card>
    </v-container>
    <!-- ventana de diálogo registros -->
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar :color="colorboton" dark
          >{{ titulo }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="formulariosedes">
            <v-row>
              <v-col class="d-flex" cols="12" sm="4" md="4">
                <v-select
                  color="green darken-2"
                  v-model="datosanuncios.cliente"
                  :items="listadoclientes"
                  item-text="representante"
                  item-value="id_cliente"
                  label="Seleccionar Cliente"
                  dense
                  outlined
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.nombre_negocio"
                  label="Nombre Negocio"
                  placeholder="Nombre Negocio"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.eslogan"
                  label="Eslogan"
                  placeholder="Eslogan"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="4" md="4">
                <v-select
                  color="green darken-2"
                  v-model="datosanuncios.categoria"
                  :items="categories"
                  item-text="nombre"
                  item-value="id_categoria"
                  label="Seleccionar Categoria"
                  dense
                  outlined
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.celular1"
                  label="Celular 1"
                  placeholder="Celular 1"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.celular2"
                  label="Celular 2"
                  placeholder="Celular 2"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.direccion"
                  label="Direccion"
                  placeholder="Direccion"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.barrio"
                  label="Barrio"
                  placeholder="Barrio"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  color="green darken-2"
                  v-model="datosanuncios.descripcion_negocio"
                  outlined
                  label="Descripcion del Negocio"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.facebook"
                  label="Facebook"
                  placeholder="Facebook"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.whatsapp"
                  label="Whatsap"
                  placeholder="Whatsapp"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  color="green darken-2"
                  v-model="datosanuncios.instagram"
                  label="Instagram"
                  placeholder="Instagram"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="12">
                <v-file-input
                  color="green darken-2"
                  v-model="datosanunciosmenu"
                  :rules="rules"
                  accept="image/png, image/jpeg, image/bmp"
                  show-size
                  label="Menu"
                  outlined
                  dense
                  id="archivosubir"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="green darken-2"
                      v-model="datosanuncios.fecha_inicio"
                      label="FECHA INICIO"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datosanuncios.fecha_inicio"
                    @input="menu1 = false"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="green darken-2"
                      v-model="datosanuncios.fecha_fin"
                      label="FECHA FIN"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datosanuncios.fecha_fin"
                    @input="menu2 = false"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" elevation="8" small @click="dialog = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  :color="colorboton"
                  elevation="8"
                  small
                  v-on:click="
                    boton == 'Registrar'
                      ? RegistrarAnuncio(datosanuncios)
                      : ActualizarAnuncio(datosanuncios)
                  "
                >
                  {{ boton }}
                  <v-icon right small> {{ claseicono }} </v-icon></v-btn
                >
              </v-card-actions>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- ventana de diálogo cargar imagen negocio -->
    <v-dialog v-model="cargarimagenes" max-width="900" max-height="900">
      <v-card>
        <v-toolbar color="primary" dark> Imagenes</v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex" cols="12" sm="12">
              <v-file-input
                color="green darken-2"
                :rules="rules"
                v-model="imagen_url"
                accept="image/png, image/jpeg, image/bmp"
                show-size
                label="Subir Imagenes"
                outlined
                dense
                id="archivosubir"
                multiple
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                elevation="8"
                small
                @click="cargarimagenes = false"
                >Cancelar</v-btn
              >
              <v-btn
                color="success"
                elevation="8"
                small
                @click="GuardarImagenes()"
              >
                Guardar <v-icon right small>mdi-floppy</v-icon></v-btn
              >
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- ventana de diálogo Imagen Principal -->
    <v-dialog v-model="modalimagenes" max-width="900" max-height="900">
      <v-card>
        <v-toolbar color="primary" dark> Imagenes</v-toolbar>
        <v-container fluid>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Documento</th>
                  <th>Nombre</th>
                  <th>Usuario</th>
                  <th>Estado</th>
                  <th v-if="permisoacceso.x == '1'">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="img in imagenespornegocio" :key="img.id_imagen">
                  <td>{{ img.id_negocio_numero }}</td>
                  <td>
                    <v-img
                      style="cursor: pointer"
                      max-height="73"
                      max-width="240"
                      :src="img.ruta"
                    ></v-img>
                  </td>
                  <td>{{ img.usuario }}</td>
                  <td>
                    <div v-if="img.tipo == 'P'">
                      <v-chip class="ma-2" color="success" label small>
                        Principal
                      </v-chip>
                    </div>
                    <div v-else>
                      <v-chip
                        class="ma-2"
                        color="red"
                        text-color="white"
                        label
                        small
                      >
                        Secundaria
                      </v-chip>
                    </div>
                  </td>
                  <td v-if="permisoacceso.x == '1'">
                    <v-btn
                      fab
                      rounded
                      x-small
                      dark
                      color="success"
                      @click="CambiarTipo(img.id_imagen, img.id_negocio_numero)"
                    >
                      <v-icon> mdi-camera-switch </v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
export default {
  components: {},

  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        // {text: 'id_sede',
        //           align: 'center',
        //           filterable: false,
        //           sortable: false,
        //           value: 'id_sede',
        // },
        { text: "PAGOS", value: "pagos" },
        { text: "ESTADO COBRO", value: "estadopagos" },
        { text: "REPRESENTANTE", value: "representante" },
        { text: "NEGOCIO", value: "nombre_negocio" },
        { text: "CATEGORIA", value: "nombre" },
        { text: "DIAS FALTANTES", value: "dias_faltantes" },
        { text: "FECHA INICIAL", value: "fecha_inicio" },
        { text: "FECHA FINAL", value: "fecha_fin" },
        { text: "ESTADO", value: "estado" },
        { text: "ACCIONES", value: "actions" },
      ],
      listadoanuncios: [],
      listadoclientes: [],
      categories: [],
      menu1: false,
      menu2: false,
      datosanuncios: {
        cliente: "",
        nombre_negocio: "",
        eslogan: "",
        categoria: "",
        celular1: "",
        celular2: "",
        direccion: "",
        barrio: "",
        descripcion_negocio: "",
        facebook: "",
        whatsapp: "",
        instagram: "",
        fecha_inicio: "",
        fecha_fin: "",
      },
      datosanunciosmenu: [],
      cargarimagenes: false,
      imagen_url: "",
      datonegocioanuncio: "",
      modalimagenes: false,
      imagenespornegocio: [],
      rules: [
        (v) =>
          !v || v.size < 5000000 || "Las Imagenes Superan mas de los 5 MB!",
      ],
      titulo: "",
      boton: "",
      colorboton: "",
      claselargo: "",
      claseicono: "",
      dialog: false,
      permisoacceso: [],
      html:""
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[4];
    this.ListarAnuncios();
    this.ListarClientes();
    this.ListaCategorias();
  },
  methods: {
    ListarAnuncios: function () {
      axios.get("admin/listaranuncios").then(
        function (response) {
          this.listadoanuncios = response.data;
        }.bind(this)
      );
    },
    ListarClientes: function () {
      axios.get("admin/listarclientes").then(
        function (response) {
          this.listadoclientes = response.data;
        }.bind(this)
      );
    },
    ListaCategorias() {
      axios.get("admin/listarcategorias").then(
        function (response) {
          this.categories = response.data;
        }.bind(this)
      );
    },
    getColor(estado) {
      if (estado == "I") return "red";
      else if (estado == "A") return "green";
      else return "orange";
    },
    AbrirModalAgregar() {
      this.dialog = true;
      this.titulo = "Agregar Anuncio";
      this.boton = "Registrar";
      this.colorboton = "primary";
      this.claselargo = "4";
      this.claseicono = "mdi-floppy";
      this.$refs.formulariosedes.reset();
    },

    SeleccionarAnuncio(datos) {
      this.ListarClientes();
      this.ListaCategorias();
      this.titulo = "Editar Anuncio";
      this.boton = "Actualizar";
      this.colorboton = "success";
      this.claselargo = "6";
      this.claseicono = "mdi-pencil";
      this.datosanuncios = datos;
      this.dialog = true;
    },
    ActualizarAnuncio(datosanuncio) {
      if (
        datosanuncio.cliente == "" ||
        datosanuncio.cliente == null ||
        datosanuncio.cliente == undefined
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        let formData = new FormData();
        formData.append("menu", this.datosanunciosmenu);
        formData.append("datos", JSON.stringify(datosanuncio));
        const datospermitidos = { "Content-Type": "multipart/form-data" };
        axios
          .post("/admin/actualizaranuncio", formData, datospermitidos)
          .then((respuesta) => {
            var msg = JSON.parse(respuesta.data[0].salida);
            this.datosanunciosmenu = "";
            this.ListarAnuncios();
            this.dialog = false;
            if (msg.CODIGO == 0) {
              this.$swal.fire({
                title: "Exito",
                text: msg.MENSAJE,
                icon: "success",
              });
            } else {
              this.$swal.fire({
                title: "No Actualizado",
                text: msg.MENSAJE,
                icon: "error",
              });
            }
          });
      }
    },
    // ---------------------------------------------------------------------------------------------------------
    RegistrarAnuncio() {
      if (
        this.datosanuncios.cliente == "" ||
        this.datosanuncios.cliente == null ||
        this.datosanuncios.cliente == undefined
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        let formData = new FormData();
        formData.append("menu", this.datosanunciosmenu);
        formData.append("datos", JSON.stringify(this.datosanuncios));
        const datospermitidos = { "Content-Type": "multipart/form-data" };
        axios
          .post("/admin/insertaranuncio", formData, datospermitidos)
          .then((data) => {
            var respuesta = data.data[0];
            if (respuesta.id == 0) {
              this.datosanunciosmenu = "";
              this.ListarAnuncios();
              this.dialog = false;
              this.$swal.close();
              this.$swal.fire(
                "Exito",
                "La imagen y datos se cargaron correctamente",
                "success"
              );
            } else {
              this.$swal.fire(
                "Intentar de nuevo",
                "No se logro cargar la imagen",
                "info"
              );
            }
          });
      }
    },
    // ---------------------------------------------------------------------------------------------------------
    CargarIMG(datosnegocio) {
      this.datonegocioanuncio = datosnegocio;
      this.cargarimagenes = true;
    },
    GuardarImagenes() {
      console.log(this.imagen_url);
      if (
        this.imagen_url == "" ||
        this.imagen_url == null ||
        this.imagen_url == undefined
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        var i = 0;

        while (i < this.imagen_url.length) {
          let formData = new FormData();
          formData.append("imagenes", this.imagen_url[i]);
          formData.append("datos", JSON.stringify(this.datonegocioanuncio));
          formData.append("nombre", JSON.stringify(i));
          const datospermitidos = { "Content-Type": "multipart/form-data" };
          axios
            .post("/admin/insertarimagenes", formData, datospermitidos)
            .then((data) => {
              console.log(data);
            });
          i++;
        }
        this.$swal.close();
        setTimeout(() => {
          this.$swal.fire({
            title: "Exitoso",
            text: "Imagenes Cargadas Exitosamente",
            icon: "success",
          });
        }, 1000);
      }
    },
    VerImagenesNegocio(codigo) {
      axios.get(`admin/todaslasimagenes/${codigo}`).then(
        function (response) {
          this.imagenespornegocio = response.data;
          this.modalimagenes = true;
        }.bind(this)
      );
    },
    CambiarTipo(imagen, negocio) {
      var datosenviados = {
        imagen: imagen,
        negocio: negocio,
      };
      axios.post("admin/imagenprincipal", datosenviados).then((respuesta) => {
        this.VerImagenesNegocio(negocio);
        if (respuesta.data.CODIGO == 0) {
          this.$swal.fire({
            title: "Exito",
            text: respuesta.data.MENSAJE,
            icon: "success",
          });
        } else {
          this.$swal.fire({
            title: "No Registrado",
            text: respuesta.data.MENSAJE,
            icon: "error",
          });
        }
      });
    },
    Cargar_Cuotas_Pago(id_negocio) {
      this.$swal
        .fire({
          title: "Numero de Cuotas ?",
          input: "number",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Si Actualizar",
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (result.value == "") {
              this.$swal.fire("Ups!!", "Por Favor Escriba la clave", "info");
            } else {
              const datosenviar = {
                id_negocio: id_negocio,
                cuotas: result.value,
              };
              axios
                .post("admin/asignarcuotas", datosenviar)
                .then((respuesta) => {
                  this.ListarAnuncios();
                  if (respuesta.data.CODIGO == 0) {
                    this.$swal.fire({
                      title: "Exito",
                      text: respuesta.data.MENSAJE,
                      icon: "success",
                    });
                  } else {
                    this.$swal.fire({
                      title: "No Actualizado",
                      text: respuesta.data.MENSAJE,
                      icon: "error",
                    });
                  }
                });
            }
          }
        });
    },
    // ---------------------------------------------------------------------------------------------------------
    CambiarEstado(id_negocio, estado) {
      if(estado == 'A'){
        this.html = `<v-row>
                    <v-col cols="12" md="6" sm="6">
                  <input type="date" id="fechainicio" name="fechainicio"/>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                  <input type="date" id="fechafin" name="fechafin"/>
                    </v-col>
                    </v-row>`;
      }else{
      this.html = "";
      }
      this.$swal
        .fire({
          title: "Actualizar Estado",
          text: "Desea Cambiar el Estado",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Si Cambiar",
          html: this.html
        })
        .then((result) => {
          if (result.isConfirmed) {
            const datosenviar = {
              id_negocio: id_negocio,
              estado: estado,
              fechainicio: estado == 'A' ? document.getElementsByName('fechainicio')[0].value : "",
              fechafin: estado == 'A' ? document.getElementsByName('fechafin')[0].value : ""
            };
            if((datosenviar.estado == 'A' && datosenviar.fechainicio == '') || (datosenviar.estado == 'A' && datosenviar.fechafin == '')){
          this.$swal.fire({title: "Requerido",text: "Seleccione una fecha inicio y fecha fin",icon: "question"});
            }else{
            axios
              .post("admin/cambiarestado", datosenviar)
              .then((respuesta) => {
                this.ListarAnuncios();
                if (respuesta.data.CODIGO == 0) {
                  this.$swal.fire({
                    title: "Exito",
                    text: respuesta.data.MENSAJE,
                    icon: "success",
                  });
                } else {
                  this.$swal.fire({
                    title: "No Actualizado",
                    text: respuesta.data.MENSAJE,
                    icon: "error",
                  });
                }
              });
          }
          }
        });
    },
  },
};
</script>

<style scoped>

</style>