<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">CLIENTES</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w =='1'" class="justify-end">
        <v-btn color="success" @click="Agregacliente()">Agregar Cliente</v-btn>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
          color="green darken-2"
            v-model="cedulaonombre"
            label="Numero o Nombre"
            placeholder="Numero o Nombre"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn depressed color="success" @click="ListarClientes(cedulaonombre)"
            >Buscar<v-icon right dark>mdi-magnify</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-simple-table v-if="listadoclientes != ''">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Documento</th>
              <th>Nombre</th>
              <th>Direccion</th>
              <th>Telefono</th>
              <th>Estado</th>
              <th v-if="permisoacceso.x =='1'">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cli in listadoclientes" :key="cli.id_cliente">
              <td>{{ cli.cedula_representante }}</td>
              <td>{{ cli.representante }}</td>
              <td>{{ cli.direccion }}</td>
              <td>{{ cli.telefono }}</td>
              <td>
                <div v-if="cli.estado == 'A'">
                  <v-chip class="ma-2" color="success" label small>
                    Activo
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip
                    class="ma-2"
                    color="red"
                    text-color="white"
                    label
                    small
                  >
                    Inactivo
                  </v-chip>
                </div>
              </td>
              <td v-if="permisoacceso.x =='1'">
                <v-btn  fab
                  rounded
                  x-small
                  dark
                  color="primary"
                  @click="seleccionarcliente(cli)"
                ><v-icon> mdi-pencil </v-icon></v-btn>
                <v-btn v-if="cli.estado =='A'"  fab
                  rounded
                  x-small
                  dark
                  color="red"
                  @click="CambiarEstado(cli.id_cliente,'I',cli.cedula_representante)"
                ><v-icon> mdi-thumb-down</v-icon></v-btn>
                <v-btn  v-if="cli.estado =='I'" fab
                  rounded
                  x-small
                  dark
                  color="success"
                  @click="CambiarEstado(cli.id_cliente,'A',cli.cedula_representante)"
                ><v-icon> mdi-thumb-up</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
        <!-- ventana de diálogo cargar imagen slider -->
        <v-dialog v-model="agregarcliente" max-width="900" max-height="900">
          <v-card>
            <v-toolbar color="primary" dark>{{titulo}}</v-toolbar>
            <v-container fluid>
              <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              color="green darken-2"
                v-model="datosclientes.documento"
                label="documento"
                placeholder="documento"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              color="green darken-2"
                v-model="datosclientes.nombre"
                label="nombre"
                placeholder="nombre"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              color="green darken-2"
                v-model="datosclientes.direccion"
                label="Direccion"
                placeholder="Direccion"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              color="green darken-2"
                v-model="datosclientes.telefono"
                label="telefono"
                placeholder="telefono"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
              
              <v-row class="justify-center">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    elevation="8"
                    small
                    @click="agregarcliente = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    :color="colorboton"
                    elevation="8"
                    small
                    v-on:click="
                  boton == 'Registrar' ? GuardarClientes() : ActualizarClientes()"
                  >
                    Guardar <v-icon right small>{{claseicono}}</v-icon></v-btn
                  >
                </v-card-actions>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
  </v-container>
    <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
export default {
  components: {},

  data() {
    return {
      titulo: "",
      boton: "",
      colorboton: "",
      claseicono: "",
      cedulaonombre:"",
        listadoclientes:[],
      agregarcliente: false,
      datosclientes:{
        documento:"",
        nombre:"",
        direccion:"",
        telefono:""
      },
      permisoacceso: [],
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[3];
  },
  methods: {
       ListarClientes: function (datosbusqueda) {
        if(datosbusqueda == "" || datosbusqueda == null || datosbusqueda == undefined){
          this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Especificar un nombre o una cedula",
          "info"
        );
        } else {
          axios.get(`clientes/listarclientes/${datosbusqueda}`).then(
            function (response) {
              var msg = JSON.parse(response.data[0].salida);
              if(msg.CODIGO == 0){
                this.listadoclientes = msg.DATA;
                   this.$swal.fire({
                     title:"Exito",
                     text:msg.MENSAJE,
                     icon:"success"})
                 } else {
                  this.listadoclientes = [];
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:msg.MENSAJE,
                     icon:"error"})
                 }
            }.bind(this)
          );
        }
      },
         // ---------------------------------------------------------------------------------------------------------
         Agregacliente() {
      this.agregarcliente = true;
      this.titulo = "Agregar Cliente";
      this.boton = "Registrar";
      this.colorboton = "primary";
      this.claseicono = "mdi-floppy";
      this.datosclientes.documento = "";
this.datosclientes.nombre = "";
this.datosclientes.direccion = "";
this.datosclientes.telefono = "";
    },
    GuardarClientes() {
      if (this.datosclientes.documento == "" || this.datosclientes.documento == null || this.datosclientes.documento == undefined ||
      this.datosclientes.nombre == "" || this.datosclientes.nombre == null || this.datosclientes.nombre == undefined ||
      this.datosclientes.direccion == "" || this.datosclientes.direccion == null || this.datosclientes.direccion == undefined ||
      this.datosclientes.telefono == "" || this.datosclientes.telefono == null || this.datosclientes.telefono == undefined
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        axios.post("/clientes/guardarcliente",this.datosclientes)
          .then((respuesta) => {
            this.agregarcliente = false;
            if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
          });
      }
    },
         // ---------------------------------------------------------------------------------------------------------
         seleccionarcliente(cliente) {
      this.titulo = "Editar Cliente";
      this.boton = "Actualizar";
      this.colorboton = "success";
      this.claseicono = "mdi-pencil";
      this.datosclientes.documento = cliente.cedula_representante;
      this.datosclientes.nombre = cliente.representante;
      this.datosclientes.direccion = cliente.direccion;
      this.datosclientes.telefono = cliente.telefono;
      this.agregarcliente = true;
    },
    ActualizarClientes() {
      if (this.datosclientes.documento == "" || this.datosclientes.documento == null || this.datosclientes.documento == undefined ||
      this.datosclientes.nombre == "" || this.datosclientes.nombre == null || this.datosclientes.nombre == undefined ||
      this.datosclientes.direccion == "" || this.datosclientes.direccion == null || this.datosclientes.direccion == undefined ||
      this.datosclientes.telefono == "" || this.datosclientes.telefono == null || this.datosclientes.telefono == undefined
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        this.$swal.fire({
          title: "Cargando...",
          html: "Por Favor Espere...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        axios.post("/clientes/actualizacliente",this.datosclientes)
          .then((respuesta) => {
            this.agregarcliente = false;
            if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
          });
      }
    },
    // ---------------------------------------------------------------------------------------------------------
    CambiarEstado(id_cliente,estado,cedula_representante) {
       this.$swal.fire({
  title: 'Actualizar Estado',
  text: 'Desea Cambiar el Estado',
  icon: "question",
  showCancelButton: true,
  confirmButtonText: 'Si Cambiar',
}).then((result) => {
  if (result.isConfirmed) {
               const datosenviar = {
                 id_cliente: id_cliente,
                 estado:estado
               };
               axios.post("clientes/cambiarestado", datosenviar)
                 .then((respuesta) => {
                  this.ListarClientes(cedula_representante);
                 if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
                 })
  }});
    },
  },
};
</script>

<style>
</style>