
<template>
   <v-app>
    <div class="backgruond"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card elevation="24"
         class="pa-4"
         style="border-radius: 45px;">
          <div class="text-center">
            <v-avatar size="100" color="green lighten-4">
              <v-icon size="40" color="green">mdi-account</v-icon>
            </v-avatar>
            <h2 class="green--text">Acceso al Sistema</h2>
          </div>
          <v-form v-on:submit.prevent="login" ref="form">
            <v-card-text>
              <v-text-field
              color="green darken-2"
            outlined
             v-model="loginEmail"
                type="text"
                label="Usuario"
                placeholder="Usuario"
                prepend-inner-icon="mdi-account"
                required
          ></v-text-field>
         <v-text-field
         color="green darken-2"
             outlined
           v-model="loginPassword"
                      :type="passwordShow?'text':'password'"
                      label="Password"
                      placeholder="Password"
                      prepend-inner-icon="mdi-key"
                      :append-icon="passwordShow ? 'mdi-eye':'mdi-eye-off'"
                      @click:append="passwordShow = !passwordShow"
                      required
          ></v-text-field>
              <v-switch label="Remember me" color="green"></v-switch>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn  type="submit" color="green">
                <span class="white--text px-8">Login</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-main>
    <v-snackbar top color="green" v-model="snackbar">
      Login success
    </v-snackbar>
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  name:"Home",
  components: {},
  data: function() {
    return {
       dialog: true,
       passwordShow:false,
       snackbar:false,
    tab: 0,
    valid: true,
    loginEmail: "",
    loginPassword: "",
    show1: false,
    token:"",
    };
  },
  methods: {
    login() {
const tokenpeticion = {
         usuario: this.loginEmail,
          password: this.loginPassword,
        };
      axios.post("login",tokenpeticion).then((respuesta) => {
          if (respuesta.data.status == true) {
            this.token = respuesta.data.token;
            sessionStorage.setItem("token", this.token);
            localStorage.setItem("nombre", respuesta.data.nombre);
            localStorage.setItem("usuario", respuesta.data.usuario);
            localStorage.setItem("data", JSON.stringify(respuesta.data.permisos));
             this.$swal.fire(
              'Bienvenido',
              'Session Iniciada Correctamente',
              'success'
            );
            var date = new Date();
                 localStorage.setItem("hora",date.setTime(date.getTime() + 3 * 60 * 60 * 1000)); // asignar horas
               // localStorage.setItem("hora",date.getTime() + 30*60000); //asignar minutos
            this.$router.push({ path: '/inicio' });
          } else {
            this.$swal.fire(
              'Ups!!',
              'Usuario o Contraseña Incorrectos',
              'error'
            )
          }
        });
    },
  },
};
</script>
<style scoped>
  .backgruond{
    background-image: url('../assets/login.jpg') !important;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-size: cover;
  }
</style>
