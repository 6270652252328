<template>
  <v-app>
    <Navbar />
    <v-main class="white" style="padding: 8px 8px 0px 8px;">
    <!-- <v-main style="background: rgb(242, 243, 248)"> -->
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from '../components/includes/Navbar.vue'
import Footer from '../components/includes/Footer.vue'
export default {
    components:{
    Navbar,
    Footer
  },
  data(){
    return{
     validaciontoken:'',
    }
  },
  created() {

     this.validaciontoken = sessionStorage.getItem("token");
     if (this.validaciontoken == '' || this.validaciontoken == null) {
            this.$router.push("/");
        }
        const fecha2 = new Date().getTime();
        const fecha1 = localStorage.getItem("hora");
        const totalhora = fecha1 - fecha2;
        setInterval(function () {
            // this.$router.push({ path: '/' });
            window.location.replace("http://localhost:8080");
            localStorage.clear();
            sessionStorage.clear();
        }, totalhora);
  }
};
</script>
<style>
.titulovistas{
padding-left: 11px;
}
.lineadetitulovistas{
border-color: #4caf50 !important; 
border-top-width: 4px;
}
</style>